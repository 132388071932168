import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import Post from '../templates/Post';
import Meta from '../components/Meta';
import Layout from '../components/Layout';
import Interstitial from '../components/Interstitial';
import Disclaimer from '../components/Disclaimer';

const WhatsAppEspanol = ({ data, location }) => (
  <Layout location={location}>
    <Meta
      title="¡Salva nuestro Futuro! Vota en las elecciones de la UE – Aquí tienes cómo 🗳"
      description="Haz clic en este enlace y envía un mensaje a tus amigos en WhatsApp - ¡dales voz! ➡️"
      shareImage="espanol"
      location={location}
      site={get(data, 'site.meta')}
      whatsAppMessage={`¡Eh! ¿Eres español y vives en el Reino Unido?
¡Tenemos derecho a votar en las elecciones de la UE en el Reino Unido!

*¡¡¡IMPORTANTE !!!*
💥 Envía este mensaje a tus amigos españoles del Reino Unido - ¡Este también es nuestro hogar! 💥
Haz clic aquí: https://saveourfuture.uk/espanol

*¡Inscríbete para votar, y hazlo antes del 7 de Mayo...o sea, ahora!*
Sigue los pasos aquí:
https://saveourfuture.uk/simple


*¡¡¡IMPORTANTE!!!*
💥 Envía este mensaje a tus amigos españoles en el Reino Unido - ¡este también es nuestro hogar! 💥
Haz clic aquí: https://saveourfuture.uk/espanol

🗣 *¡¡¡ESTA VEZ NUESTRA VOZ CUENTA!!!* 🗣`}
    />
    <Interstitial>
      <p>Elige los cinco mejores amigos o grupos para enviar tu mensaje de Whatsapp.</p>
      <p>Generando mensaje ahora</p>
      <p>Puedes personalizar el mensaje en el siguiente paso ...</p>
    </Interstitial>
    <Disclaimer />
  </Layout>
);

WhatsAppEspanol.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default WhatsAppEspanol;

export const query = graphql`
  query EspanolQuery {
    site {
      meta: siteMetadata {
        title
        description
        url: siteUrl
        author
        twitter
        adsense
      }
    }
  }
`;
